@import 'core/settings';
@import 'core/mixins';

@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

@media (min-width: 1360px) {
   .container {
      max-width: 1260px; } }

@media (min-width: 1600px) {
   .container {
      max-width: 1400px; } }

.print .container button.btn-toggle {
   display: none!important; }

.color-red {
   color: red; }

@media print {
   .print .container .card:last-child {
      margin-bottom: 0; } }

.is-marge a.nav-link svg {
   display: none;
   margin-left: 5px; }

.is-marge a.nav-link.active svg {
   display: inline-block; }

.panel > .body-accounts-entries.card-body {
   padding: 15px 38px; }

.is-taxes.panel:after {
   display: none; }

.is-taxes.panel {
   overflow: visible; }


tr.show.level-2 td.description {
   padding-left: 10px; }


tr.show.level-3 td.description {
   padding-left: 20px; }


tr.show.level-4 td.description {
   padding-left: 30px; }


tr.show.level-5 td.description {
   padding-left: 40px; }


tr.show.level-6 td.description {
   padding-left: 50px; }

.print {
   color: black;
   font-family: arial, sans-serif; }

.table-revenue.table th {
  border-top: 1px solid #dee2e6; }

.width-12 {
   width: 12%; }

.width-10 {
   width: 10%; }

.width-3 {
   width: 3%; }

.width-15 {
   width: 15%; }

.width-17 {
   width: 17%; }

.cols-width-23 .col-3 {
   flex: 0 0 23%;
   max-width: 25%; }

.cols-width-23 .col-3:last-child {
   flex: 0 0 25%;
   max-width: 25%; }

.table-small {
   width: 5%; }

.flex-grow-0 {
   flex-grow: 0; }

.table-verysmall {
   width: 3%; }

.table-medium {
   width: 15%; }

.table-big {
  width: 30%; }

.table-big-account {
  width: 55%; }

.table-medium-daily {
   width: 15%; }

.table-big-daily {
   width: 40%; }

.table-medium-dre {
   width: 15%; }

.table-almostbig-dre {
   width: 25%; }

.table-big-dre {
   width: 40%; }

.mytable thead th,
.mytable tbody td {
    padding: 5px;
    white-space: normal; }

.panel.is-reason-relat {
   overflow: visible; }

.panel.is-reason-relat:after {
   display: none!important; }


.panel-header {
   background-color: #F9FAFB;
   box-shadow: 0 6px 13px 0 rgba(58, 74, 131, 0.2);
   border: 0!important;
   border-radius: 13px;
   overflow: hidden;
   .card-body {
      padding: 10px 38px; } }


.react-confirm-alert-body h1 {
   font-size: 30px; }

.react-confirm-alert-body button {
   color: #ffffff !important;
   background-color: #1f8efa;
   border-color: #1f8efa;
   font-size: 12px;
   box-shadow: unset;
   padding: 6px 14px;
   border-radius: 10rem; }

body {
   color: #919eab;
   font-family: 'Quicksand', sans-serif;
   font-size: 14px;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
   background-color: #f9fafb; }

label {
   color: #1488ca;
   font-size: 16px;
   font-weight: bold; }

.bg-light {
   background-color: #f8f8f8 !important; }

.height-100 {
   height: 100%; }

.font12 {
   font-size: 12px; }

.popover {
   border-radius: 13px;
   box-shadow: 0 6px 13px 0 rgba(58, 74, 131, 0.2);
   background-color: #ffffff;
   border: 0; }

.mytable.fixed {
   table-layout: fixed; }

.bs-popover-auto[x-placement^="top"] .arrow::before, .bs-popover-top .arrow::before {
   border-top-color: transparent; }

.bs-popover-auto[x-placement^="top"] .arrow, .bs-popover-top .arrow {
   bottom: -8px !important; }

.slick-prev {
   display: none !important; }

.slick-next {
   color: transparent;
   font-size: 0;
   background: url('../images/arrow.png') no-repeat;
   width: 17px;
   height: 34px;
   border: 0;
   position: absolute;
   right: 0;
   top: 50%;
   transform: translateY(-50%);
   cursor: pointer; }

.popover {
   z-index: 1; }

#filter {
   color: #3799d1;
   cursor: pointer; }

.filter.popover {
   min-width: 379.1px;
   z-index: 999; }

.filter .popover-body {
   color: #919eab;
   font-size: 14px;
   min-width: 379.1px;
   padding: 18px;
   .title {
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal; } }

.btn-delete {
   min-width: auto !important; }


.print {
   min-height: 100vh;
   background-color: #fff; }
.notifications-text {
   color: #919eab; }

.notifications-time {
   color: #919eab;
   font-size: 10px;
   display: block; }

table {
   font-size: 13px; }

.td-1 {
   width: 67px; }

.td-2 {
   width: 131px; }

.td-3 {
   width: 64px; }

.td-4 {
   width: 132px; }

.td-5 {
   width: 133px; }

.td-6 {
   width: 134px; }

.td-7 {
   width: 134px; }

.td-8 {
   width: 64px; }

.system {
   .td-1 {
      width: 50px; }

   .td-2 {
      width: 95px; }

   .td-3 {
      width: 46px; }

   .td-4 {
      width: 97px; }

   .td-5 {
      width: 97px; }

   .td-6 {
      width: 100px; }

   .td-7 {
      width: 100px; }

   .td-8 {
      width: 64px; } }


.mytable {
   margin-top: -14px !important;
   .bg-light {
      position: relative;
      z-index: 2; }

   thead {
      position: relative;
      th {
         border-top: 0;
         padding-bottom: .5rem;
         padding-top: .5rem; } } }

@import 'avatar';
@import 'badge';
@import 'button';
@import 'breadcrumb';
@import 'modal';
@import 'table';
@import 'form';
@import 'nav';
@import 'heading';
@import 'pagination';
@import 'card';
@import 'card-panel';
@import 'taxe';
@import 'release-notify';
@import 'station';

@import 'navbar';
@import 'daypicker';
@import 'system';
@import 'footer';
