.rc-pagination {
   margin: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   > li {
      color: #a2a3a4;
      width: 24px;
      min-width: unset;
      height: 24px;
      border: 0;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      outline: none !important;
      @include _hover {
         color: darken(#a2a3a4, 20%); }
      &.rc-pagination-item-active {
         color: #ffffff !important;
         background-color: map-get($colors, primary); } } }

