.footer {
   background-color: #f4f6f8;
   padding: 48px 0 0;
   margin-top: 100px;
   a {
      color: #1488ca;
      &:hover {
         color: darken(#1488ca, 10%); } }

   .list-inline-link {
      color: #1488ca;
      width: 26px;
      height: 26px;
      border: 1px solid #1488ca;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
         border-color: darken(#1488ca, 10%); }
      svg {
         height: 14.1px; } }

   .list-unstyled {
      margin-bottom: 0;
      li {
         margin-bottom: 7px; } }

   p {
      line-height: 1.9; } }


.footer-title {
   font-size: 12px;
   text-transform: uppercase;
   margin-bottom: 16px; }

.footer-email {
   color: #3b4698 !important;
   font-size: 12px;
   transform: translateY(-11px);
   display: block; }

.footer-phone {
   font-size: 18px;
   margin-bottom: 19px;
   display: flex;
   align-items: center;
   svg {
      color: #b0b9c3;
      height: 14px;
      margin-right: 8px; } }

.footer-copyright {
   color: #8f9ea6;
   font-size: 12px;
   padding: 60px 0 30px;
   display: block; }
