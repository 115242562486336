.modal-dialog {
   max-width: 651px; }

.modal-content {
   background-color: #ffffff;
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
   border-radius: 6px; }

.modal-header {
   border-bottom: 0;
   padding: 32px 69px 40px;
   flex-direction: column; }

.modal-body {
   padding: 0 98px; }

.modal-footer {
   border-top: 0;
   padding: 14px 69px 55px;
   justify-content: center;
   .btn {
      min-width: 174px;
      justify-content: center; } }

.modal-close {
   color: #ffffff;
   background-color: transparent;
   border: 0;
   padding: 0;
   position: absolute;
   right: -25px;
   top: 0;
   cursor: pointer;
   svg {
      height: 22px; } }
