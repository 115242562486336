.release {
   position: relative; }

.release-title {
   font-size: 12px;
   font-weight: bold; }

.release-collapse {
   max-height: 97px !important;
   position: relative;
   transition: unset !important;
   .slick-list {
      &:after {
         content: '';
         background-color: #d8d8d8;
         width: calc(100% - 50px);
         height: 1px;
         position: absolute;
         left: 25px;
         bottom: 10px;
         z-index: -1; } } }

.release-item {
   position: relative;
   .item-header {
      > .title {
         color: #1488ca;
         font-size: 12px;
         font-weight: bold;
         display: block; }
      > .date {
         font-size: 12px;
         margin-bottom: 10px;
         display: block; } }
   .item-content {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background-color: #919eab;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(2px);
      &.success {
         background-color: #81d135; }
      &.info {
         background-color: #53d769;
         cursor: pointer; }
      &.danger {
         background-color: #ce0b24; }
      svg {
         color: #ffffff;
         height: 10px; } } }
