.login {
   width: 749px;
   margin: 62px auto 0;
   padding: 45px 100px !important; }

.login-picture {
   text-align: center; }

.login-content {
   position: relative;
   > .login-title {
      font-size: 30px;
      line-height: 1;
      margin: 20px 0; } }
