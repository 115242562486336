.DayPicker {
   width: 100%;
   outline: none; }

.DayPicker-wrapper {
   width: 100%;
   padding: 0; }

.DayPicker-Month {
   margin: 0;
   outline: none; }

.DayPicker-Week, .DayPicker-WeekdaysRow {
   display: grid;
   grid-template-columns: repeat(7, 1fr);
   align-content: center; }

.DayPicker-Weekday {
   font-size: 19px;
   text-align: center;
   font-weight: bold;
   margin: 15px 0;
   abbr {
      text-decoration: none;
      cursor: unset; } }

.DayPicker-Day--outside {
   color: #d8d8d8; }

.DayPicker-Day--selected {
   background-color: unset !important; }

.DayPicker-Day {
   font-size: 30px;
   text-align: center;
   font-weight: bold;
   padding: 0;
   width: 57px;
   height: 57px;
   margin: 5px auto;
   display: flex;
   align-items: center;
   justify-content: center;
   &--outside {
      color: #d8d8d8; }
   &--selected {
      color: #ffffff !important;
      position: relative;
      z-index: 1;
      background-color: #1f8efa !important; } }

.DayPicker-Day--today {
   color: #3598d1; }

.DayPicker-Caption {
   color: #1488ca;
   font-size: 14px;
   text-align: center;
   margin-top: -22px; }

.DayPicker-NavBar {
   padding-top: 15px;
   display: flex;
   align-items: center;
   justify-content: space-around; }

.DayPicker-NavButton {
   background: url('../images/arrow-calendar.png') no-repeat;
   width: 12px;
   height: 16px;
   position: relative;
   top: unset;
   right: unset; }

.DayPicker-NavButton--prev {
   transform: rotate(180deg); }

.DayPickerInput-OverlayWrapper {
   .DayPicker-Weekday {
      font-size: 18px;
      margin: 0; }
   .DayPicker-Day {
      font-size: 20px;
      width: 30px;
      height: 30px; } }
