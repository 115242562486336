.nav-tabs {
   .nav-item {
      margin: 0; }
   .nav-link {
      color: #4e4e4e;
      border: 0;
      padding: 10px 25px !important;
      position: relative;
      cursor: pointer;
      > .doubt {
         position: absolute;
         right: 0;
         top: 0; }
      &::after {
         content: '';
         background-color: #1b86f9;
         width: 100%;
         height: 5px;
         border-top-left-radius: 5px;
         border-top-right-radius: 5px;
         position: absolute;
         left: 0;
         bottom: 0;
         opacity: 0; }
      &:hover {
         color: #4e4e4e; }
      &.active {
         color: #1b86f9 !important;
         font-weight: bold;
         background-color: transparent;
         &::after {
            opacity: 1; } } } }

.reportnavbar {
   margin-bottom: -1px;
   .nav-link {
      padding: 15px !important; } }
