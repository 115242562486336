.system {
   margin-top: 58px;
   display: grid;
   grid-template-columns: auto 1fr;
   grid-gap: 37px;
   &.active {
      .system-navbars {
         width: 620px; }
      .system-content .panel::after {
         right: 0; }
      .system-navs {
         transform: translateX(0) !important; }
      .system-user {
         background-color: #f8f8f8;
         margin-left: 322px !important; } }
   .DayPicker {
      min-width: 704px; }
   .DayPicker-Month {
      width: 100%; } }

.system-navbars {
   width: 293px;
   position: relative;
   transition: .5s;
   > .card {
      height: calc(100% - 20px);
      min-height: 798px;
      overflow: hidden; } }

.system-content {
   min-width: 100%;
   transition: .5s;
   .panel {
      &::after {
         content: '';
         background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.73) 38%, #ffffff);
         width: 114px;
         height: 100%;
         position: absolute;
         top: 0;
         right: -114px;
         transition: right .5s; }
      > .card-body {
         min-width: 730px; } }
   &.is-loading {
      display: flex;
      align-items: center;
      justify-content: center; }
   .card-header {
      min-width: 780px; } }


.system-navs {
   box-shadow: 0 6px 13px 0 rgba(58, 74, 131, 0.2);
   width: 322px;
   height: 100%;
   padding: 28px 25px !important;
   overflow: hidden;
   transform: translateX(-400px);
   position: absolute;
   top: 0;
   left: 0;
   transition: .5s;
   .navs-heading {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ebebeb;
      margin: 30px 0 10px;
      padding: 6px 0;
      display: flex;
      align-items: center;
      &::before {
         content: '';
         background-color: #3598d1;
         width: 4px;
         height: 37px;
         margin-right: 14px;
         display: block; } }
   .navs-content {
      display: flex; }
   .navs-search {
      color: #1488ca;
      position: relative;
      svg {
         height: 12px;
         position: absolute;
         top: 13px;
         right: 16px;
         z-index: 1; } } }
.system-user {
   padding: 42px 29px !important;
   position: relative;
   transition: .5s;
   .button-search {
      color: #1488ca;
      background-color: transparent;
      border: 0;
      padding: 0;
      position: absolute;
      top: 15px;
      left: 20px;
      cursor: pointer;
      svg {
         height: 12px; } }
   &.is-loading {
      display: flex;
      align-items: center;
      justify-content: center; }

   .media {
      margin-bottom: 57px;
      align-items: center;
      > a {
         position: relative; } }

   .media-object {
      width: 80px;
      height: 80px;
      border-radius: 50%; }

   .media-heading {
      margin: 0; }

   .media-body {
      font-size: 12px;
      margin: 0 17px; }

   .nav-link {
      color: #4e4e4e;
      margin-bottom: 28px;
      padding: 0;
      &.active {
         color: #1f8ffa;
         font-weight: bold; } } }

.btn-useredit {
   color: #ffffff;
   background-color: #53d769;
   border: 0;
   border-radius: 50%;
   width: 21px;
   height: 21px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   bottom: 0;
   z-index: 1;
   cursor: pointer;
   svg {
      min-width: 10px; } }

.system-filte {
   list-style: none;
   padding: 0;
   margin: 0;
   li {
      color: #d9d9d9;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 19px;
      width: 19px;
      height: 19px;
      margin: 2px 0;
      border-radius: 50%;
      cursor: pointer;
      &.active {
         color: #ffffff;
         background-color: map-get($colors, primary); } } }

.system-users {
   list-style: none;
   width: 100%;
   padding: 0;
   margin: 0;
   li {
      padding-left: 15px; }
   .media {
      height: 48px;
      padding: 8px 15px;
      border-radius: 5px;
      align-items: center;
      cursor: pointer;
      &.active {
         background-color: #1c88f9;
         .media-heading {
            color: #ffffff;
            font-weight: bold; }
         .media-object, .media-body > span {
            color: #ffffff;
            display: block; } } }
   .media-object {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 9px;
      display: none; }
   .media-heading {
      font-size: 14px;
      font-weight: normal;
      margin: 0; }
   .media-body {
      font-size: 10px;
      > span {
         display: none; } } }

.system-taxes-spinner {
   height: 268px;
   display: flex;
   align-items: center;
   justify-content: center; }

.system .header-content {
   margin-top: 11px !important; }

.modal-media {
   width: max-content;
   margin: 20px auto;
   align-items: center;
   > a {
      position: relative; }

   .media-object {
      width: 80px;
      height: 80px;
      border-radius: 50%; }

   .media-heading {
      margin: 0; }

   .media-body {
      font-size: 12px;
      margin: 0 17px; } }
