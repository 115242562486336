

$colors: (
   primary: #1f8efa
);


$badge-colors: (
   primary: #ffffff,
   secondary: #ffffff,
   info: #3f5c8c,
   success: #417505,
   danger: #823737,
   dark: #727272
);

$badge-backgrounds: (
   primary: #368cc9,
   secondary: #395ba6,
   info: #e8f5ff,
   success: #e8ffec,
   danger: #ffe6e6,
   dark: #e9e9e9
);

$breakpoints: (
   sm: 576px,
   md: 768px
);
