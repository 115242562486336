.badge {
   font-size: 12px;
   font-weight: 700;
   min-width: 96px;
   border-radius: 6px;
   padding: 9px; }

.badge-pill {
   border-radius: 2rem; }

@each $key, $value in $badge-colors {
	.badge-#{$key} {
		color: $value;
		background-color: map-get($badge-backgrounds, $key); } }
