.breadcrumb {
   font-size: 12px;
   font-weight: bold;
   background-color: transparent;
   min-height: 50px;
   margin: 10px 0 0;
   padding: 0;
   align-items: center;
   .breadcrumb-item {
      color: #1488ca;
      &::after {
         content: '>>';
         color: #1488ca;
         display: inline-block;
         margin-left: 10px; } } }
