.form-control {
   color: #222;
   font-size: 14px;
   background-color: #f8f8f8;
   min-height: 40px;
   border: 1px solid #bdd1e4;
   border-radius: 4px;
   padding: 10px 23px;
   resize: none;
   &:focus {
      color: #222;
      background-color: #ffffff;
      box-shadow: none;
      border-color: #3598d1; }
   &.search {
      background-color: #ffffff; }
   &::-webkit-input-placeholder {
      color: #919eab; }
   &::-moz-placeholder {
      color: #919eab; }
   &:-ms-input-placeholder {
      color: #919eab; }
   &:-moz-placeholder {
      color: #919eab; }
   &.is-border {
      color: #919eab;
      font-size: 14px;
      font-weight: bold;
      background-color: transparent;
      box-shadow: none;
      border: 0;
      &::-webkit-input-placeholder {
         color: #c7c7c7; }
      &::-moz-placeholder {
         color: #c7c7c7; }
      &:-ms-input-placeholder {
         color: #c7c7c7; }
      &:-moz-placeholder {
         color: #c7c7c7; } } }


.form-group {
   margin-bottom: 18px; }

.custom-file {
   z-index: 0;
   &.active {
      .custom-file-label {
         color: #222222; } } }

.custom-file-label {
   color: #919eab;
   font-size: 14px;
   line-height: 40px;
   background-color: #f8f8f8;
   min-height: 40px;
   padding: 0 23px;
   border: 1px solid #bdd1e4;
   border-radius: 4px; }

.custom-file-label::after {
   content: "Anexa" !important;
   font-size: 14px;
   line-height: 40px;
   font-weight: bold;
   color: #ffffff;
   border-color: #3698d1;
   background-color: #3698d1;
   height: 40px;
   padding: 0 1.5rem;
   transform: translate(1px, -1px); }

.DayPickerInput {
   width: 100%;
   > input {
      color: #222;
      font-size: 14px;
      background: #f8f8f8 url('../images/calendar.png') no-repeat right;
      background-position-y: 50%;
      background-position-x: 95%;
      width: 100%;
      min-height: 40px;
      border: 1px solid #bdd1e4;
      border-radius: 4px;
      padding: 8px 23px;

      &::-webkit-input-placeholder {
         color: #919eab;
         opacity: 1; }
      &::-moz-placeholder {
         color: #919eab;
         opacity: 1; }
      &:-ms-input-placeholder {
         color: #919eab;
         opacity: 1; }
      &:-moz-placeholder {
         color: #919eab;
         opacity: 1; } } }
.css-1492t68 {
   color: #919eab !important; }

.css-1aya2g8 {
   color: #919eab;
   font-size: 14px;
   background-color: #f8f8f8 !important;
   min-height: 40px !important;
   border: 1px solid #bdd1e4 !important;
   border-radius: 4px !important; }

.css-2o5izw {
   box-shadow: none !important;
   border-color: #3598d1 !important; }

.css-1rtrksz {
   padding: 5px 23px !important; }

.css-d8oujb {
   display: none !important; }

.css-19bqh2r {
   fill: #3698d1 !important;
   width: 16px; }

.group-input {
   max-width: 376px;
   margin: auto;
   display: grid;
   grid-gap: 1.7rem;
   grid-template-columns: repeat(2, 1fr);
   > div {
      display: flex;
      align-items: center;
      justify-content: center; } }

.custom-operador {
   .custom-checkbox {
      padding-left: 0;
      text-align: center;
      .custom-control-label {
         color: #5f5f5f;
         width: 33px;
         height: 33px;
         padding-top: 0;
         padding-left: 0; }
      .custom-control-label::before {
         width: 33px;
         height: 33px;
         border: 0!important;
         background: transparent!important;
         content: '-';
         top: -10px;
         left: 0;
         text-align: center;
         font-size: 33px;
         box-shadow: unset!important; }
      .custom-control-input:checked ~ .custom-control-label::before {
         content: '+'!important;
         color: #5f5f5f!important; }
      .custom-control-label::after {
         display: none; } } }




.custom-checkbox {
   .custom-control-label::before {
      width: 22px;
      height: 22px;
      border-radius: 3px;
      border: solid 1px #cccccc;
      background-color: #f8f8f8; } }

.custom-radio, .custom-checkbox {
   .custom-control-label {
      color: #5f5f5f;
      font-size: 14px;
      font-weight: normal;
      padding-top: 5px;
      padding-left: 10px;
      cursor: pointer; }

   .custom-control-label::before {
      width: 22px;
      height: 22px; }

   .custom-control-input:checked ~ .custom-control-label::before {
      background: #ffffff url('../images/check.png') no-repeat center;
      border: solid 1px #87c8f9; }

   .custom-control-label::after {
      display: none; } }

.input-group-text {
   color: #222;
   border: 1px solid #bdd1e4;
   font-size: 14px;
   min-width: 65px; }
