.calendar {
   position: relative;
   .reminder {
      color: #8B9898;
      font-size: 12px;
      display: block; } }

.calendar-panel {
   width: 435px;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 5;
   box-shadow: 0 6px 13px 2px rgba(58, 74, 131, 0.3);
   overflow: unset;
   .heading {
      text-align: center;
      border-bottom: 1px solid #eee;
      padding-bottom: 15px; }
   .close {
      position: absolute;
      right: -25px;
      top: 0;
      svg {
         height: 22px; } } }

.calendar-panel-item {
   display: flex;
   align-items: center;
   justify-content: space-between;

   > span {
      color: #1488ca;
      font-size: 14px;
      font-weight: bold; }
   .btn {
      color: #ffffff !important;
      border: 0;
      border-radius: 30px; } }

.releases-notify-alert {
   height: 355px;
   display: flex;
   align-items: center;
   justify-content: center; }
