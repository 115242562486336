.card {
   border: 1px solid #e0e0e0;
   border-radius: 9px;
   margin-bottom: 20px;

   .table {
      margin: 0;
      tbody {
         > tr:first-child {
            th, td {
               border-top: 0; } } } } }

.card-body {
   padding: 14px 25px;
   > .row {
      align-items: center; } }


.card-header {
   background: transparent; }

.card-header-tabs {
   margin: 25px -25px -31px; }
