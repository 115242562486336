.release-notify {
   margin-bottom: 13px;
   .card-title {
      font-size: 12px;
      font-weight: bold;
      margin: 0; }
   .card-body {
      display: flex;
      align-items: center;
      justify-content: space-between; } }
