.taxe {
   white-space: nowrap;
   min-height: 60px;
   position: relative;
   .row {
      min-height: 29px;
      font-size: 13px; }
   .bg-light {
      padding: 7px; } }

.taxe-maturity {
   color: #f8b21c;
   font-size: 12px;
   font-weight: bold;
   display: flex;
   align-items: center;
   position: relative;
   svg {
      height: 15px;
      margin-right: 6px; } }

.taxe-question {
   display: flex;
   align-items: center;
   svg {
      fill: #3598d1;
      height: 13px;
      margin-left: 10px; } }
