/* Breakpoints */
@mixin media($breakpoint, $size: max) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media only screen and (#{$size}-width: map-get($breakpoints, $breakpoint)) {
			@content; } }
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`.  Available breakpoints are: #{map-keys($breakpoints)}."; } }

// create focus and hover mixin
@mixin _hover {
	&:hover,
	&:focus,
	&:active {
		@content; } }
