.panel {
   background-color: #ffffff;
   box-shadow: 0 6px 13px 0 rgba(58, 74, 131, 0.2);
   border: 0;
   border-radius: 13px;
   overflow: hidden;
   > .card-body {
      padding: 30px 38px;
      > .card:last-child {
         margin-bottom: 0 !important; } }

   > .card-header .header-content {
      margin: 30px 0 -17px;
      display: flex;
      align-items: center;
      justify-content: space-between; }

   > .card-footer {
      background: #fff;
      padding: 0px 38px 30px 38px;
      border: 0; }

   &.is-marge {
      > .card-header {
         padding-top: 1.5rem; }

      @include media(md, min) {
         > .card-header {
            border-bottom-width: 2px;
            padding: 30px 98px; }

         > .card-body {
            padding: 30px 98px; } } }
   &.is-taxes {
      > .card-header {
         padding: 17px 64px;
         > .heading {
            font-size: 16px;
            line-height: 3.13;
            margin: 0; } } } }
