.btn {
   font-size: 14px;
   font-weight: bold;
   box-shadow: 0 24px 48px 0 rgba(49, 127, 203, 0.2);
   border: 1px solid transparent;
   border-radius: 6px;
   padding: 9.5px 14px;
   display: inline-flex;
   align-items: center;
   svg {
      margin-right: 7px; } }

.btn-popover {
   color: #979797;
   min-width: 21px;
   height: 21px;
   border: solid 0.7px #979797;
   border-radius: 50%;
   padding: 0;
   svg {
      margin-right: 0;
      margin-left: 9px; } }

.btn-pill {
   border-radius: 10rem; }

.btn-cicle {
   color: #1488ca;
   background-color: transparent;
   min-width: 22px;
   height: 22px;
   border: 2px solid #1488ca;
   border-radius: 50%;
   padding: 0;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   &:hover {
      opacity: .8; } }

.btn-cicle {
   border-width: 1px;
   > svg {
      height: 24px;
      transform: translateX(1.3px); } }

.btn-toggle {
   background: url('../images/btn-toggle.png') no-repeat center;
   min-width: 21px;
   height: 22px;
   border: 0;
   cursor: pointer;
   outline: none !important;
   &.active {
      background: url('../images/btn-toggle-active.png') no-repeat center; } }

.btn-createuser {
   color: #ffffff;
   background-image: linear-gradient(209deg, #3598d1, #3b4698);
   box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
   width: 34px;
   height: 34px;
   border: 0;
   border-radius: 24px;
   position: absolute;
   right: 24px;
   top: 105px;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: .3s;
   cursor: pointer;
   svg {
      height: 18px; }
   &:hover {
      opacity: .8; } }

.btn-xs {
   font-size: 10px;
   box-shadow: unset;
   padding: 2px 10px;
   svg {
      height: 9px;
      margin-right: 3px; } }

.btn-sm {
   font-size: 12px;
   box-shadow: unset;
   padding: 6px 14px;
   svg {
      height: 15px; } }

.btn-lg {
   font-size: 16.8px;
   padding: 11.4px 22px;
   svg {
      height: 20px; } }

.btn-primary {
   color: #ffffff !important;
   background-color: map-get($colors, primary);
   border-color: map-get($colors, primary);
   @include _hover {
      background-color: darken(map-get($colors, primary), 10%);
      border-color: darken(map-get($colors, primary), 10%); } }

.btn-outline-primary {
   color: map-get($colors, primary);
   box-shadow: none;
   border-color: map-get($colors, primary);
   @include _hover {
      background-color: map-get($colors, primary);
      border-color: map-get($colors, primary); } }

.btn-badge, .btn-delete {
   font-size: 12px;
   font-weight: 700;
   height: 30px;
   min-width: 116px;
   border: 0;
   border-radius: 5rem;
   padding: 9px 15px;
   justify-content: space-between;
   line-height: 1;

   @each $key, $value in $badge-colors {
      &.btn-#{$key} {
         color: $value !important;
         background-color: map-get($badge-backgrounds, $key) !important;
         @include _hover {
            color: darken($value, 5%) !important;
            background-color: darken(map-get($badge-backgrounds, $key), 5%) !important; } } } }

.dropdown-menu {
   background-color: #ffffff;
   box-shadow: 0 6px 13px 0 rgba(58, 74, 131, 0.2);
   border: 0;
   margin-top: 5px; }

.dropdown-item {
   color: #4e4e4e;
   font-size: 12px;
   font-weight: bold;
   padding: .5rem 1.5rem;
   cursor: pointer; }

.btn-link {
   font-size: 10px;
   box-shadow: none;
   padding: 0; }
