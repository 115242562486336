.chat {
   .modal-header {
      padding: 32px 69px 15px; }
   .modal-body {
      padding: 0 69px 0; }
   .form-group {
      padding: 0 25px; }
   .alert {
      margin: 9rem 0; } }

.chat-content {
   height: 350px;
   margin-bottom: 25px;
   padding: 0;
   position: relative;
   .css-y1c0xs {
      padding: 0 25px; }
   &.is-loading {
      display: flex;
      align-items: center;
      justify-content: center; } }

.message {
   &.is-your {
      .message-name {
         text-align: right; }
      .message-content {
         background-color: #eeffdd; } } }

.message-name {
   font-size: 12px;
   font-weight: bold;
   margin-bottom: 4px;
   display: block; }

.message-content {
   color: #919eab;
   font-size: 14px;
   background-color: #ffffff;
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.23);
   border-radius: 4px;
   padding: 11px 23px;
   position: relative;
   word-wrap: break-word; }

.message-time {
   color: #646567;
   font-size: 10px;
   position: absolute;
   bottom: 11px;
   right: 23px; }
