.modal-create-user {
   max-width: 520px !important;

   .modal-content {
      box-shadow: 0 6px 13px 0 rgba(58, 74, 131, 0.2) !important;
      border-radius: 13px; }

   .modal-header {
      padding: 25px 45px 20px; }

   .modal-body {
      padding: 0 30px; }

   .modal-footer {
      padding: 14px 30px 30px; }

   .modal-tabs {
      margin-bottom: 28px;
      justify-content: center; }

   .nav-tabs {
      border-bottom: 2px solid #dee2e6;
      .nav-item {
         margin-bottom: -1px !important; } } }
