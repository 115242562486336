.navbar {
   background-color: #ffffff;
   border-bottom: 1px solid #dcdcdc;
   padding: 0 1rem;
   position: unset;
   > .container {
      min-height: 63px; } }

.navbar-brand {
   img {
      width: 176.3px;
      min-height: 46.3px; }
   @include media(lg) {
      order: 1; } }

.navbar-right {
   margin-left: 28.4px; }

.navbar-nav {
   width: 100%;
   padding: 0 1rem;
   justify-content: space-between;
   .nav-link {
      color: #919eab;
      font-weight: bold;
      position: relative;
      &::before {
         display: none; }
      &::after {
         content: '';
         background-image: linear-gradient(264deg, #3598d1, #3b4698);
         box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
         width: 0%;
         min-width: 0;
         max-width: 145px;
         height: 8px;
         border-radius: 4px;
         position: absolute;
         left: 50%;
         bottom: -14px;
         transform: translateX(-50%);
         transition: width .5s, min-width .5s, opacity .3s;
         opacity: 0;
         pointer-events: none; }
      &:hover, &:focus, &:active, &.active {
         color: #1488ca;
         &::after {
            width: 100%;
            min-width: 121px;
            opacity: 1; } } } }

.navbar-user {
   display: flex;
   align-items: center;
   @include media(lg) {
      order: 2; } }

.navbar-avatar {
   z-index: 3;
   > .btn {
      background-color: transparent !important;
      box-shadow: none;
      border: 0;
      margin: 0;
      padding: 0;
      > .avatar {
         width: 44px;
         height: 44px; } } }

.navbar-notifications {
   margin-left: 10px;
   z-index: 3;
   > .btn {
      color: #1488ca;
      background-color: transparent;
      box-shadow: unset;
      border: 0;
      padding: 0;
      position: relative;
      outline: none !important;
      &::after {
         content: '';
         background-color: #4cd964;
         width: 11px;
         height: 11px;
         border: 2px solid #ffffff;
         border-radius: 50%;
         position: absolute;
         top: -4px;
         right: -2px; }
      &:focus, &:active, &.active {
         color: #1488ca !important;
         background-color: transparent !important; } }
   svg {
      height: 17px;
      margin: 0; }
   @include media(lg) {
      display: none; }
   .dropdown-menu-card {
      box-shadow: 0 6px 13px 0 rgba(58, 74, 131, 0.2);
      width: 580px;
      border: 0;
      border-radius: 13px;
      padding: 30px 38px;
      margin-top: 30px;
      max-height: 750px;
      overflow: scroll;
      position: relative;
      &:after {
         content: "";
         display: inline-block;
         vertical-align: middle;
         margin-right: 10px;
         width: 0;
         height: 0;
         border-left: 11px solid transparent;
         border-right: 11px solid transparent;
         border-bottom: 15px solid #fff;
         position: absolute;
         right: 26px;
         top: -9px; } } }

.bg-overlay {
   background-color: rgba(84, 84, 84, 0.79);
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 2;
   display: none;
   &.active {
      display: block; } }
